<template>
  <div class="wrapper">
    <leftmenu />
    <router-view class="content-wrapper" />
    <!-- <bottom /> -->
  </div>
</template>
<script>
// import Top from './Top'
import Leftmenu from './Leftmenu'

import Bottom from './Bottom.vue'
export default {
  components: {
    // Top,
    Leftmenu,
    Bottom
  },
  mounted() {
    this.$root.$emit('changeBodyClass', {
      remove: 'login-page',
      add: 'sidebar-mini'
    })
  }
}
</script>
<style>
table.b-table[aria-busy='true'] {
  opacity: 0.6;
}
</style>
