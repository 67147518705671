import { axiosPost, axiosPostToUrl, axiosGet } from './config'
import router from '../router'
import store from '../store'

export const preLogin = (admin_id, admin_password) => {
  return axiosPostToUrl('auth/preLogin', { admin_id, admin_password })
}

export const login = (login_token, auth_no) => {
  return axiosPostToUrl('auth/login', { login_token, auth_no })
}

export const otpLogin = (admin_id, admin_password, otp_code) => {
  return axiosPostToUrl('auth/otpLogin', { admin_id, admin_password, otp_code })
}

export const logout = () => {
  const refreshToken = store.getters['user/getRefreshToken']
  axiosPost('auth/logout', { refreshToken }).then(response => {
    if (response.code !== '0000') {
      alert(response.message)
    } else {
      store.dispatch('user/clearUser')
      router.push('/')
    }
  })

  // axiosPost('/auth/admin_logout', {}).then(response => {
  //   if (response.result_code !== '__OK__') {
  //     console.error('logout fail', response.result_code)
  //   }
  //   store.dispatch('user/clearUser')
  //   router.push('/')
  // })
}

/**
 * 사용자 리스트를 페이지 단위로 가져오기
 * @param {*} curPage
 * @param {*} rowPerPage
 * @param {*} searchType
 * @param {*} searchText
 * @returns
 */
export const getUserList = (
  curPage = 1,
  rowPerPage = 30,
  searchType = '',
  searchText = '',
  startDate = '',
  endDate = '',
  storeCd = [],
  searchDate = null
) => {
  var url = `user?curPage=${curPage}&rowPerPage=${rowPerPage}&searchType=${searchType}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&storeCd=${storeCd}&searchDate=${searchDate}`
  console.log(url)
  return axiosGet(url)
}

/**
 * Admin에 의한 강제 로그아웃 처리
 * @param {string} usersn
 */
export const user_logout = usersn => {
  axiosPost('user_logout_by_admin', { usersn }).then(response => {
    if (response.result_code !== '__OK__') {
      alert('강제로그아웃 실패', response.result_code)
    } else {
      alert(usersn + '님 강제로그아웃 처리 완료')
    }
  })
}

export const getNewUsertCnt = () => {
  var url = 'user/today'
  return axiosGet(url)
}

export const getUserCode = () => {
  var url = `comm/code/103`
  return axiosGet(url)
}

export const getUserWithdrawawl = (
  curPage = 1,
  rowPerPage = 20,
  searchType = '',
  searchText = '',
  startDate = '',
  endDate = ''
) => {
  // withdrawal?curPage=1&rowPerPage=20&searchType=&searchText=&startDate&endDate
  var url = `user/withdrawal?curPage=${curPage}&rowPerPage=${rowPerPage}&searchType=${searchType}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}`
  return axiosGet(url)
}

/**
 * 추천가입자 리스트
 * @param {*} num
 * @returns
 */
export const getRecommUserList = num => {
  var url = `user/recom/${num}`
  return axiosGet(url)
}

/**
 * 수강권 지급 현황
 * @param {*}} curPage
 * @param {*} rowPerPage
 * @param {*} searchType
 * @param {*} searchText
 * @returns
 */
export const getVoucherList = (
  curPage = 1,
  rowPerPage = 20,
  searchType = '',
  searchText = '',
  startDate = '',
  endDate = ''
) => {
  // http://{{adm}}/adm_api/user/event?curPage=1&rowPerPage=20&searchType=&searchText=
  var url = `user/event?curPage=${curPage}&rowPerPage=${rowPerPage}&searchType=${searchType}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}`
  return axiosGet(url)
}

/**
 * 회원상세
 * @param {*} user_sn
 * @returns
 */
export const getUserDetail = user_sn => {
  // http://{{adm}}/adm_api/user/1000001
  var url = `user/${user_sn}`
  return axiosGet(url)
}

export const getPayment = (user_sn, curPage = 1, rowPerPage = 20, startDate, endDate) => {
  // http://{{adm}}/adm_api/user/payment/1000001?curPage=1&rowPerPage=20&startDate=&endDate=
  var url = `user/payment/${user_sn}?curPage=${curPage}&rowPerPage=${rowPerPage}&startDate=${startDate}&endDate=${endDate}`

  return axiosGet(url)
}

/**
 * 사용자 강제 로그아웃
 * @param {*} user_sn
 */
export const lougoutUser = async user_sn => {
  const resp = await axiosPost(`user/logout/${user_sn}`)
  if (resp.code === '0000') {
    return resp
  } else {
    alert(resp.message)
  }
}

/**
 * 쿠폰사용내역
 * @param {*} user_sn
 * @param {*} curPage
 * @param {*} rowPerPage
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export const getCouponUsage = async (
  user_sn,
  curPage = 1,
  rowPerPage = 30,
  startDate = '',
  endDate = ''
) => {
  // http://{{adm}}/adm_api/user/coupon/1000001?curPage=1&rowPerPage=20&startDate=&endDate=
  const url = `user/coupon/${user_sn}?curPage=${curPage}&rowPerPage=${rowPerPage}&startDate=${startDate}&endDate=${endDate}`
  const resp = await axiosGet(url)
  return resp
}

/**
 * 학습내역
 * @param {*} user_sn
 * @param {*} curPage
 * @param {*} rowPerPage
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export const getStudyHistory = async (
  user_sn,
  curPage = 1,
  rowPerPage = 30,
  startDate = '',
  endDate = ''
) => {
  //http://{{adm}}/adm_api/user/lecture/1000018?curPage=1&rowPerPage=20&startDate=&endDate=
  const url = `user/lecture/${user_sn}?curPage=${curPage}&rowPerPage=${rowPerPage}&startDate=${startDate}&endDate=${endDate}`
  const resp = await axiosGet(url)
  return resp
}

/**
 * 유저 요약 정보
 * @returns
 */
export const getUserSummary = (
  curPage = 1,
  rowPerPage = 30,
  searchType = '',
  searchText = '',
  startDate = '',
  endDate = '',
  storeCd = []
) => {
  var url = `user/summary?curPage=${curPage}&rowPerPage=${rowPerPage}&searchType=${searchType}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&storeCd=${storeCd}`
  return axiosGet(url)
}

export const checkRecomCode = code => {
  return axiosGet('user/referral/' + code)
}

export const setRecomCode = async (code, recom_sn, user_sn, is_event) => {
  const resp = await axiosPost(`user/referral`, { code, recom_sn, user_sn, is_event })
  if (resp.code === '0000') {
    return resp
  } else {
    alert(resp.message)
  }
}

export const searchRecomProduct = code => {
  return axiosGet('user/referral/' + code + '/event')
}

/**
 * 사용자 리스트를 페이지 단위로 가져오기
 * @param {*} curPage
 * @param {*} rowPerPage
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export const getBatchUserCountList = (
  curPage = 1,
  rowPerPage = 30,
  startDate = '',
  endDate = ''
) => {
  var url = `user/count?curPage=${curPage}&rowPerPage=${rowPerPage}&searchType=&searchText=&startDate=${startDate}&endDate=${endDate}`
  return axiosGet(url)
}

/**
 * 사용자 접속 리스트를 페이지 단위로 가져오기
 * @param {*} curPage
 * @param {*} rowPerPage
 * @param {*} searchType
 * @param {*} searchText
 * @returns
 */
export const getUserLogin = (
  curPage = 1,
  rowPerPage = 30,
  searchType = '',
  searchText = '',
  startDate = '',
  endDate = ''
) => {
  var url = `user/login?curPage=${curPage}&rowPerPage=${rowPerPage}&searchType=${searchType}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}`
  return axiosGet(url)
}
