import { axiosGet, axiosPut, axiosPost } from './config'
// import router from '../router'
// import store from '../store'

/**
 * 보상 사유
 * @returns
 */
export async function getRewardType() {
  try {
    var url = 'comm/code/112'
    var response = await axiosGet(url)

    return response.data
  } catch (error) {
    console.error('getRewardType', error)
  }
}
/**
 * 상품 코드 리스트(수강권/구독권)(118)
 * @returns
 */
export async function getVoucherType() {
  try {
    var url = 'comm/code/118'
    var response = await axiosGet(url)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

/**
 * 결제 수단 리스트(신용/체크타드, 네이버페이...)(115)
 * @returns
 */
export async function getPaymentType() {
  try {
    var url = 'comm/code/115'
    var response = await axiosGet(url)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

/**
 * 환불수단(116)
 * @returns
 */
export async function getPayRefundType() {
  try {
    var url = 'comm/code/116'
    var response = await axiosGet(url)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

/**
 * 환불상태
 * @returns
 */
export async function getPayRefundState() {
  try {
    var url = 'comm/code/117'
    var response = await axiosGet(url)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

/**
 * 환불상태
 * @returns
 */
export async function getCode(code) {
  try {
    var url = `comm/code/${code}`
    var response = await axiosGet(url)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getMenu = () => {
  return axiosGet('comm/menu')
}
