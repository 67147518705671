import Vue from 'vue'

Vue.filter('commaFilter', function(value) {
  if (value && typeof value === 'number') value = value + ''
  if (value && typeof value === 'string') {
    const dotIndex = value.indexOf('.')
    if (dotIndex > -1) {
      const convertVal = value.substring(0, dotIndex).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      const remainingVal = value.substring(dotIndex)
      return `${convertVal}${remainingVal}`
    } else {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  } else {
    return '-'
  }
})

Vue.filter('dateFilter', value => {
  // const PAD = txt => {
  //   if (txt < 10) {
  //     return '0' + txt
  //   } else {
  //     return txt
  //   }
  // }
  // if (value) {
  //   var dt = new Date(value)
  //   return `${PAD(dt.getUTCFullYear())}-${PAD(dt.getUTCMonth() + 1)}-${PAD(dt.getUTCDate())} ${PAD(
  //     dt.getHours()
  //   )}:${PAD(dt.getMinutes())}:${PAD(dt.getUTCSeconds())}`
  // }
  return value
})
