const user = JSON.parse(sessionStorage.getItem('user'))
const initialState = user
  ? { status: { isLogin: true }, user }
  : { status: { isLogin: false }, user: null }

const getters = {
  getUser: state => {
    return state.user
  },
  getUserLevel: state => {
    return state.user.ADMIN_LEVEL
  },
  getToken: state => {
    // accessToken
    return state.user.LOGIN_TOKEN
  },
  getRefreshToken: state => {
    // refreshtoken
    return state.user.REFRESH_TOKEN
  },
  isLogin: state => {
    return state.status.isLogin
  }
}

const mutations = {
  SET_USER(state, payload) {
    state.status = { isLogin: true }
    state.user = Object.assign({}, payload)
  },
  CLEAR_USER(state) {
    state.status = { isLogin: false }
    state.user = null
  },
  RESET_TOKEN(state, payload) {
    state.status = { isLogin: true }
    state.user.LOGIN_TOKEN = payload.LOGIN_TOKEN
    state.user.REFRESH_TOKEN = payload.REFRESH_TOKEN
  },
  SET_USER_LEVEL(state, payload) {
    state.user.ADMIN_LEVEL = payload
  }
}

const actions = {
  setUser: ({ commit }, payload) => {
    sessionStorage.setItem('user', JSON.stringify(payload))
    commit('SET_USER', payload)
  },
  clearUser: ({ commit }) => {
    sessionStorage.removeItem('user')
    commit('CLEAR_USER')
  },
  updateUserLevel: ({ commit, state }, payload) => {
    const user = state.user
    user.ADMIN_LEVEL = payload
    sessionStorage.setItem('user', JSON.stringify(user))
    commit('SET_USER', user)
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
